<template>
 <div class="isMain">
  <navbar
    v-if="namePath !== ''"
    :namePath="namePath"
    ></navbar>
    <router-view></router-view>
    <section-footer
    v-if="namePath[0] !== 'a'"
    ></section-footer>
 </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import SectionFooter from '@/components/SectionFooter'
import SectionLoading from './components/SectionLoading/SectionLoading.vue'
import axios from 'axios'

export default {
  data() {
    return {
      namePath: '',
      file: '',
      isLoading:false,
      paramsRoute:''
    }
  },
  methods: {
  },
  watch: {
    $route(value) {
      this.namePath = value.name
    }
  },
  mounted() {
  },
  components: { Navbar, SectionFooter, SectionLoading }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior:smooth
}
.isMain {
  overflow: hidden;
}
.footer {
  position: relative;
  bottom: 0;
}
</style>